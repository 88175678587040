import { createWebHistory, createRouter } from 'vue-router'
import EmptyLayout from '../components/layout/EmptyLayout.vue';
const routes = [

    
    
     {//메인홀
        path: '/',
        name: 'home',
        component: () => import('../views/main/home.vue')
    },

    {//전시홀
        path: '/exhibitionHall',
        name: 'exhibitionHall',
        component: () => import('../views/exhibitionHall/exhibitionHall.vue')
    },

    {// 골드 부스
        path: '/goldBooth',
        name: 'goldBooth',
        component: () => import('../views/booth/goldBooth.vue')
    },

    {// 실버 부스
        path: '/silverBooth',
        name: 'silverBooth',
        component: () => import('../views/booth/silverBooth.vue')
    },

    {// 일반 부스
        path: '/generalBooth/:id',
        name: 'generalBooth',
        component: () => import('../views/booth/generalBooth.vue')
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,
   scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});



export default router